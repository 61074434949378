.box {
  position: relative;
  width: 320px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 30px;
  transition: 0.5s;
}

.box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50px;
  width: 50%;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
}

.box::after {
  content: '';
  position: absolute;
  top: 0;
  left: 50px;
  width: 50%;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
  filter: blur(30px);
  transition: 0.5s;
}

.box:hover:before,
.box:hover:after {
  transform: skewX(0deg);
  left: 20px;
  width: calc(100% - 90px);
}

.box::before,
.box::after {
  background: linear-gradient(315deg, var(--primary), var(--secondary));
}

.box span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  pointer-events: none;
}

.box span::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.5s;
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.box:hover span::before {
  top: -50px;
  left: 50px;
  width: 100px;
  height: 100px;
  opacity: 1;
}

.box span::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.5s;
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  animation-delay: -1s;
}

.box:hover span::after {
  bottom: -50px;
  right: 50px;
  width: 100px;
  height: 100px;
  opacity: 1;
}

@keyframes animate {
  0%,
  100% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-10px);
  }
}

.box .content {
  position: relative;
  left: 0;
  padding: 12px 24px;
  min-width: 18rem;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  backdrop-filter: blur(10px);
  z-index: 50;
  transition: 0.3s;
  color: #1c1c1c;
}

.box:hover .content {
  left: -25px;
  padding: 20px 30px;
}

.box .content h2 {
  font-size: 2em;
  line-height: 1.25em;
  font-weight: 700;
}

.box .content h3 {
  font-size: 1.3em;
  line-height: 1.4em;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  hyphens: auto;
}

.box .content h4 {
  font-size: 1.125em;
  font-weight: 500;
  line-height: 1.1em;
}

.box .content h5 {
  font-size: 1em;
  font-weight: 400;
}

.box .content a {
  display: inline-block;
  font-size: 1.1em;
  color: #fff;
  background: #111;
  text-align: center;
  padding: 10px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 700;
  transition-duration: 300ms;
}

.box .content a:hover {
  background: var(--primary);
  color: #111;
}
