.range-slider {
  width: 100%;
  position: relative;
  display: flex;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 1em;
  outline: none;
  border-radius: 3px;
  background: #909090;
  box-shadow:
    3px 3px 6px #000,
    inset 1px 1px 1px #909090;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 2.5em;
  height: 2.5em;
  cursor: pointer;
  z-index: 3;
  position: relative;
}

.slider-thumb {
  position: absolute;
  width: 2.5em;
  height: 2.5em;
  background: #89a4c6;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  box-shadow: inset 0px 1px 2px #909090;
}

.slider-thumb::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1em;
  height: 1em;
  background: #394a5f;
  border-radius: inherit;
  transition: 0.3s;
}

.range-slider:hover .slider-thumb:before {
  background: var(--primary);
}

.tooltip {
  position: absolute;
  width: 2em;
  height: 1.5em;
  top: -1.5em;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #89a4c6;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: #000;
  border-radius: 3px;
  box-shadow:
    0px 3px 10px #1f1f1f,
    inset 0px 1px 2px #e5e5e5;
}

.tooltip::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #89a4c6;
  bottom: -0.4em;
  left: 50%;
  transform: translate(-50%);
}

.progress {
  width: 50%;
  height: 1em;
  border-radius: 3px;
  background: linear-gradient(90deg, #0a2d5a 0%, var(--primary) 100%);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  pointer-events: none;
}
