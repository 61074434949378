.radiobutton-input {
  visibility: hidden;
}

.radiobutton-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-size: 1.2rem;
  color: #fbfbfb;
  font-weight: 500;
}

.custom-radiobutton {
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: #c5ddef;
  display: inline-block;
}
.radiobutton-input:checked + .custom-radiobutton {
  background-color: var(--primary);
}

.radiobutton-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
