.inputBox {
  position: relative;
  width: 100%;
  /* margin-top: 35px; */
}

.inputBox input {
  position: relative;
  width: 100%;
  padding: 20px 10px 10px;
  background: transparent;
  outline: none;
  color: #23242a;
  font-size: 1em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  z-index: 8;
}

.inputBox span {
  position: absolute;
  left: 0;
  padding: 20px 0px 10px;
  font-size: 1em;
  color: #8f8f8f;
  pointer-events: none;
  letter-spacing: 0.05em;
  transition: 0.5s;
}

.inputBox input:valid ~ span,
.inputBox input:focus ~ span {
  color: var(--primary);
  transform: translateY(-34px);
  font-size: 0.75em;
}

.inputBox i {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background: var(--primary);
  border-radius: 4px;
  overflow: hidden;
  transition: 0.5;
}

.inputBox input:valid ~ i,
.inputBox input:focus ~ i {
  height: 44px;
}
