.box {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #222;
  border-radius: 8px;
  overflow: hidden;
}

.box::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, transparent, var(--primary), var(--primary), var(--primary));
  z-index: 1;
  transform-origin: bottom right;
  animation: animate 6s linear infinite;

  @media screen and (max-width: 1366px) {
    width: 380px;
    height: 420px;
  }
}

.box::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, transparent, var(--primary), var(--primary), var(--primary));
  z-index: 1;
  transform-origin: bottom right;
  animation: animate 6s linear infinite;
  animation-delay: -3s;

  @media screen and (max-width: 1366px) {
    width: 380px;
    height: 420px;
  }
}

.box form {
  position: absolute;
  inset: 4px;
  background: #18181b;
  padding: 50px 40px;
  border-radius: 8px;
  z-index: 2;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1366px) {
    padding: 36px 28px;
  }
}

.box form h2 {
  color: var(--primary);
  font-size: 1.75rem;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.1em;

  @media screen and (max-width: 1366px) {
    font-size: 1.5rem;
  }
}

.box form input[type='submit'] {
  padding: 9px 25px;
  background: var(--primary);
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  margin-top: 28px;

  @media screen and (max-width: 1366px) {
    margin-top: 24px;
  }
}

.box form input[type='submit']:active {
  opacity: 0.8;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
