@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #0f9cd8;
  --secondary: #ffcd01;
  --bg-color: #1c1c1c;
}

body {
  background: var(--bg-color);
  font-family: 'JetBrains Mono', monospace;
}

::-webkit-scrollbar {
  width: 0.8rem;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(transparent, var(--primary));
  border-radius: 0.4rem;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(transparent, var(--primary) 20%, var(--primary));
  border-radius: 0.4rem;
}

::-webkit-scrollbar-track {
  background: var(--bg-color);
}
